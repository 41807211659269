<template>
    <div class="page">
        <div class="content">
            <div class="top">
                <Input v-model="storePrams.keyword" suffix="ios-search" placeholder="请输入姓名、手机号" style="width: 15vw;" />
                <Select v-model="storePrams.status" style="width: 10vw;margin: 0 30px;" placeholder="请选择状态">
                    <Option v-for="item in stateList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
                <Button type="primary" style="background: #296FE1;" @click="search">搜索</Button>
                <Button style="margin-left: 30px;" @click="exportData">导出</Button>
            </div>
            <div style="min-height: 70vh;">
                <Table ref="selectionTable" :columns="columnsTable" :data="dataTable" border >
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="title">
                        <div v-if="row.jobs">{{row.jobs.title}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="jobs">
                        <div v-if="row.jobs">{{row.jobs.name}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="name">
                        <div v-if="row.user">{{row.user.name}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="phone">
                        <div v-if="row.user">{{row.user.phone}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="card_id">
                        <div v-if="row.user.card_id">{{row.user.card_id}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="success_num">
                        <div v-if="row.success_num">{{row.success_num}}</div>
                        <div v-else>0</div>
                    </template>
                    <template slot-scope="{ row }" slot="profit">
                        <div v-if="row.file">{{row.file.profit}}</div>
                        <div v-else>{{row.jobs.profit}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="wages">
                        <div v-if="row.file">{{row.file.wages}}</div>
                        <div v-else>{{row.jobs.wages}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="fail_count">
                        <div style="display: flex;justify-content: center;">
                            <div v-if="row.fail_count" class="table-span" @click="goNoshow(row)">{{row.fail_count}}</div>
                            <div v-else class="table-span" @click="goNoshow(row)">0</div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="manage">
                        <div>
                            <div v-if="row.status == 0" style="display: flex;flex-direction: row;align-content: center;justify-content: space-around;">
                                <Button type="primary" ghost @click="goPrompt(1,row)" label="small">录取</Button>
                                <Button type="error" ghost @click="goPrompt(4,row)" label="small">拒绝</Button>
                            </div>
                            <div v-if="row.status == 1" style="display: flex;flex-direction: row;align-content: center;justify-content: space-around;">
                                <Button type="primary" ghost @click="goPrompt(3,row)" label="small">正常结算</Button>
                                <Button type="primary" ghost @click="goNoshowNum(row)" label="small">放鸽子</Button>
                            </div>
                        </div>
                    </template>
                </Table>
            </div>
            <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
        </div>
        <!-- 放鸽子记录 -->
        <Modal v-model="noshowModal" title="放鸽子记录" width="50">
            <div>
                <Table :columns="columnsTableModal" :data="dataTableModal" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="title">
                        <div v-if="row.jobs">{{row.jobs.title}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="jobs">
                        <div v-if="row.jobs">{{row.jobs.name}}</div>
                    </template>
                </Table>
                <Page class="Page" :current="storePramsModal.page" :page-size="storePramsModal.limit" @on-change="pageChangeModal" :total="totalModal" show-elevator show-total />
            </div>
            <div slot="footer"></div>
        </Modal>
        <!-- 提示 -->
        <Modal v-model="promptModal" title="提示">
            <div class="content-modal" style="min-height: 100px;">
                <div v-if="promptValue == 1" class="p-modal">是否确定录取该报名!</div>
                <div v-if="promptValue == 4" class="p-modal">是否确定拒绝该申请!</div>
                <div v-if="promptValue == 3" class="p-modal">是否确定正常结算!</div>
            </div>
            <div slot="footer">
                <Button @click="cancelDelModal">取消</Button>
                <Button type="primary" style="background: #296FE1;" @click="saveDelModal">确定</Button>
            </div>
        </Modal>
        <!-- 放鸽子 -->
        <Modal v-model="noshowNumModal" title="放鸽子">
            <div style="display: flex;flex-direction: column;align-items: center;">
                <div>
                    <div class="p-modal">被{{customName}}客户放鸽子,是否标记一下?</div>
                    <Input v-model="inputValue" maxlength="100" type="textarea" placeholder="请输入标记内容" style="width: 350px;margin-top: 20px;" />
                </div>
            </div>
            <div slot="footer">
                <Button @click="cancelNumModal">取消</Button>
                <Button type="primary" style="background: #296FE1;" @click="saveNumModal">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { signupList, signup_modify_status, signup_fail_list, signupExport } from "@/api/index";
    export default {
        data() {
            return {
                total: 0,
                totalModal: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keyword: '',
                    status: '',
                    jobs_id: ''
                },
                columnsTable: [
                    // {type: 'selection', width: 60, align: 'center'},
                    {title: '#',slot: "id",align:'center',width:'80px'},
                    {title: '标题',slot: 'title',align:'center'},
                    {title: '客户名称',slot: 'name',align:'center'},
                    {title: '手机号',slot: 'phone',align:'center',width:'130px'},
                    {title: '身份证号',slot: 'card_id',align:'center',width:'180px'},
                    {title: '成功结算次数',slot: 'success_num',align:'center'},
                    {title: '放鸽子次数',slot: 'fail_count',align:'center'},
                    {title: '利润',slot: 'profit',align:'center'},
                    {title: '工资',slot: 'wages',align:'center'},
                    {title: '押金',key: 'deposit',align:'center'},
                    {title: '时间',key: 'created_at',align:'center',width: '200px'},
                    {title: '状态',key: 'status_text',align:'center'},
                    {title: '操作',slot: 'manage',align:'center',width:'200px'}
                ],
                dataTable: [],
                stateList: [
                    { value: '-1', label: '全部' },
                    { value: '0', label: '待录取' },
                    { value: '1', label: '已录取' },
                    { value: '2', label: '放鸽子' },
                    { value: '3', label: '成功结算' },
                    { value: '4', label: '拒绝' }
                ],
                noshowModal: false,
                columnsTableModal: [
                    {title: '#',slot: "id",align:'center',width:'80px'},
                    {title: '标题',slot: 'title',align:'center'},
                    {title: '职位名称',slot: 'jobs',align:'center'},
                    {title: '标记',key: 'reason',align:'center'},
                    {title: '时间',key: 'created_at',align:'center'}
                ],
                dataTableModal: [],
                promptModal: false,
                noshowNumModal: false,
                inputValue: '',
                customName: '',
                promptValue: '',
                stateData: {
                    id: '',
                    status: '',
                    reason: ''
                },
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    user_id: ''
                },
                selectedData: []
            }
        },
        created(){},
        mounted(){
            if(this.$route.query.id){
                this.storePrams.jobs_id = this.$route.query.id
            }else{
                this.storePrams.jobs_id = ''
            }
            this.signupList()
        },
        methods: {
            search(){
                this.signupList()
            },
            signupList(){
                signupList(this.storePrams).then(res => {
                    this.dataTable = res.data.data
                    this.total = res.data.total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            signup_fail_list(){
                signup_fail_list(this.storePramsModal).then(res => {
                    this.dataTableModal = res.data.data
                    this.totalModal = res.data.total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.signupList()
            },
            pageChangeModal(index){
                this.storePramsModal.page = index;
                this.signup_fail_list()
            },
            goNoshow(e){
                this.storePramsModal.user_id = e.user_id
                this.noshowModal = true
                this.signup_fail_list()
            },
            goPrompt(i,e){
                this.promptValue = i
                this.promptModal = true
                this.stateData.id = e.id
                this.stateData.status = i
                this.stateData.reason = ''
            },
            cancelDelModal(){
                this.promptModal = false
            },
            saveDelModal(){
                this.promptModal = false
                this.signup_modify_status()
            },
            signup_modify_status(){
                signup_modify_status(this.stateData).then(res => {
                    this.signupList()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            goNoshowNum(e){
                this.noshowNumModal = true
                this.customName = e.custom_name
                this.stateData.id = e.id
                this.stateData.status = 2
            },
            cancelNumModal(){
                this.noshowNumModal = false
            },
            saveNumModal(){
                this.noshowNumModal = false
                this.stateData.reason = this.inputValue
                this.signup_modify_status()
            },
            //导出
            exportData(){
                // 获取已选中的行数据
                // this.selectedData = this.$refs.selectionTable.getSelection();
                this.signupList()           
                let data = {
                    status: this.storePrams.status
                }
                signupExport(data).then(res=>{
                    window.open(res.data)
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}
.top{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
}
.table-btn{
    padding: 0 3px;
    height: 22px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #296FE1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #296FE1;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}
.table-span{
    width: 72px;
    height: 28px;
    border-radius: 2px;
    border: 1px solid #999999;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}
.Page{
    margin-top: 20px;
    text-align: right;
}
.content-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.p-modal{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
    background: #F5F9FA;
}
// table中所有的边框 
::v-deep .ivu-table td{
    border-left: none;
    border-right: none;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
    color: #666666 !important;
}
</style>